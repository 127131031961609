module.exports = {
  title: `Chip's Blog`,
  description: `A blog about technology, code, and other stuff.`,
  author: `Chip Wasson`,
  introduction: `I enjoy working with new technologies, find new applications of current technologies, and archiving digital things.`,
  siteUrl: `https://chp.sh`, // Your blog site url
  social: {
    twitter: `chpwssn`, // Your Twitter account
    github: `chpwssn`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'chpwssn/blog', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: '', // Add your google analytics tranking ID
}
